
import Vue, { defineComponent } from "vue";
import axios from "axios";

interface CalldripMessage {
  MessageId: string;
  ReceivedTime: string;
  apikey: string;
  apiuser: string;
  xml: string;
  leadID: string;
}

export default defineComponent({
  name: "Calldrip",
  data() {
    return {
      messages: [] as Array<CalldripMessage>,
      refreshTimer: 0 as number,
      start: "" as string,
      end: "" as string,
      organizationId: "" as string,
      status: "" as string,
      userID: "" as string,
      response: "" as string,
    };
  },
  mounted() {
    this.refreshTimer = setInterval(() => {
      this.getData();
    }, 1000);
  },
  methods: {
    async getData() {
      const response = await axios.get<Array<CalldripMessage>>(
        "api/mock/v1/calldrip/frontend/messages"
      );
      this.messages = response.data.reverse();
    },
    async clearData() {
      await axios.post("api/mock/v1/calldrip/frontend/clear");
      this.messages = [];
    },
    setEnvironmentUrl(url: string) {
      this.start = url + "/v1/feedback/startcall";
      this.end = url + "/v1/feedback/endcall";
    },
    async sendFeedBack(mId: string, resp: string) {
      var stat = null;
      var retUrl = this.end;
      switch (resp) {
        case "startgesprek":
            retUrl = this.start;
            stat = resp;
            break;
        case "nietbereikt":
          stat = this.status;
          break;
        default:
            stat = resp;
            break;
      }

      await axios({
        method: "post",
        url: "api/mock/v1/calldrip/frontend/feedback",
        headers: {},
        data: {
          MessageId: mId,
          OrganizationId: this.organizationId,
          UserId: this.userID,
          Status: stat,
          ReturnUrl: retUrl,
        },
      }).then((response) => this.response = response.statusText).catch((error) => this.response = error);
    },
  },
  beforeUnmount() {
    clearInterval(this.refreshTimer);
  },
});
