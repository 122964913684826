import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ClickToCall from '@/views/ClickToCall.vue';
import Home from '@/views/Home.vue';
import Calldrip from '@/views/Calldrip.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/ClickToCall',
        component: ClickToCall
    },
    {
        path: '/Calldrip',
        component: Calldrip
    }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;