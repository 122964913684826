
import Vue, { defineComponent } from 'vue';
import axios from 'axios';

interface ClickToCallMessage {
        user_id: number;
        account_id: number;
        user_phone_number: string;
        customer_phone_number: string; 
        customer_first_name: string;
        customer_last_name: string;
}

export default defineComponent({
    name: "ClickToCall",
    data() {
        return { messages: [] as Array<ClickToCallMessage>,
                refreshTimer: 0 as number,
            }
    },
    mounted() {
        this.refreshTimer = setInterval(() => {this.getData()}, 1000)
    },
    methods: {
        async getData() {
            const response = await axios.get<Array<ClickToCallMessage>>('/api/mock/v1/clicktocall/frontend/messages');
            this.messages = response.data.reverse();
        },
        async clearData() {
            await axios.post("api/mock/v1/clicktocall/frontend/clear");
            this.messages = [];
        },
        
    },
    beforeUnmount() {
        clearInterval(this.refreshTimer);
    }
    
    
})
